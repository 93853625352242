<template>
    <v-app id="app">
        <Navbar v-if="isPrepared" />
        <v-main>
            <v-container v-if="isPrepared" class="pa-0">
                <router-view />
            </v-container>
            <v-container v-else>
                <v-row>
                    <v-col>
                        <img src="@/assets/roche.png" alt="" width="100px" />
                        <div class="title">{{ title }}</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col> 歡迎使用QR-Code產生系統 </v-col>
                </v-row>
                <v-row>
                    <v-col> 待登入程序完成後將自動跳轉頁面 </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col> 正在初始化... <span v-if="isInit">完成</span></v-col>
                </v-row>
                <v-row v-if="isInit" no-gutters>
                    <v-col>登入狀態: {{ isSignIn ? '已登入' : '未登入' }}</v-col>
                </v-row>
                <v-row v-if="isInit && noState" no-gutters class="mt-10">
                    <v-col>
                        <v-btn @click="login"> 點我登入 </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="isInit && userEmail" no-gutters>
                    <v-col>使用Email: {{ userEmail }}</v-col>
                </v-row>
            </v-container>
        </v-main>
        <v-dialog v-model="wrongEmailDomain" persistent max-width="300px">
            <v-card>
                <v-card-title> {{ errorMsg }} </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="handleWrongEmailDomain">
                        Login again
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import Navbar from '@/components/Navbar';

import API from '@/models/api';
import { mapMutations } from 'vuex';
const TOKEN_PREFIX = 'roche.qr-code.user';
import _ from 'lodash';

export default {
    components: { Navbar },
    data() {
        return {
            isInit: false,
            isSignIn: false,
            userEmail: '',
            openSideDrawer: false,

            noState: false,

            wrongEmailDomain: false,
            errorMsg: '',
            title: process.env.VUE_APP_TITLE,
        };
    },
    computed: {
        isPrepared() {
            if (process.env.VUE_APP_MODE == 'DEV') {
                return true;
            }
            return this.isInit && this.isSignIn;
        },
    },
    mounted() {
        let that = this;
        let checkGauthLoad = setInterval(function () {
            that.isInit = that.$gAuth.isInit;
            if (that.isInit) {
                that.checkLocalEmail();
                clearInterval(checkGauthLoad);
            }
        }, 1000);
    },
    methods: {
        ...mapMutations({
            updateVerifyUrls: 'updateVerifyUrls',
        }),
        handleWrongEmailDomain() {
            this.wrongEmailDomain = false;
            this.login();
        },
        async checkLocalEmail() {
            const LOCAL_EMAIL = window.localStorage.getItem(`${TOKEN_PREFIX}.email`);
            const LOCAL_TOKEN = window.localStorage.getItem(`${TOKEN_PREFIX}.token`);
            let ACCESS_TOKEN;
            if (this.$route.hash) {
                const matches = this.$route.hash.match('access_token=(?<token>.*?)&');
                if (matches && matches.groups) {
                    ACCESS_TOKEN = matches.groups.token;
                }
            }
            history.replaceState(
                {},
                '',
                `${window.location.origin}${this.$route.path}`
            );
            if (!LOCAL_TOKEN || !LOCAL_EMAIL) {
                if (ACCESS_TOKEN) {
                    console.log('google logined, continue verify...');
                    this.googleLoginFlow(ACCESS_TOKEN);
                } else {
                    console.log('no state, continue login');
                    this.noState = true;
                }
            } else {
                console.log('you have state!! Continue jwt login');
                this.jwtLoginOnly(LOCAL_TOKEN);
            }
        },
        async login() {
            this.startGoogleLogin();
        },
        async googleLoginFlow(token) {
            try {
                // verify Oath Token
                const googleRes = await API.Auth.verifyOauthToken({ token });
                const GOOGLE_TOKEN = googleRes.data.token;
                const EMAIL = googleRes.data.userData.email;
                API.setToken(GOOGLE_TOKEN);
                // jwt Login
                const jwtRes = await API.Auth.verifyJwtToken();
                const JWT_TOKEN = jwtRes.data.token;
                API.setToken(JWT_TOKEN);
                this.setSignInState(EMAIL, JWT_TOKEN);
            } catch (e) {
                console.log(e.response);
                // if (e.error === "popup_closed_by_user") window.close();
                switch (e.response.data.msg) {
                    case 'Error: Google 認證失敗: 沒有權限':
                        this.errorMsg = 'Please Use Roche Email';
                        this.reLogin();
                }
            }
        },
        async startGoogleLogin() {
            const PARAMETERS = {
                client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                scope: 'profile email',
                redirect_uri: window.location.origin,
                response_type: 'token',
                prompt: 'select_account',
            };
            const queryString = _.map(PARAMETERS, (v, k) => `${k}=${v}`).join('&');
            window.location.href =
                `https://accounts.google.com/o/oauth2/v2/auth?` + queryString;
        },
        async jwtLoginOnly(token) {
            try {
                API.setToken(token);
                const jwtRes = await API.Auth.verifyJwtToken();
                const JWT_TOKEN = jwtRes.data.token;
                const EMAIL = jwtRes.data.userData.email;
                API.setToken(JWT_TOKEN);
                this.setSignInState(EMAIL, JWT_TOKEN);
            } catch (e) {
                switch (e.response.data.msg) {
                    case 'Error: Google 認證失敗: 沒有權限':
                        this.errorMsg = 'Please Use Roche Email';
                        this.reLogin();
                        break;
                    case 'Error: 沒有權限或權限過期，請重新登入':
                        this.errorMsg = '權限過期，請重新登入';
                        this.reLogin();
                        break;
                }
            }
        },
        async setSignInState(email, token) {
            const res = await API.getLineUrl();
            this.updateVerifyUrls(res.data);
            this.isSignIn = true;
            window.localStorage.setItem(`${TOKEN_PREFIX}.email`, email);
            window.localStorage.setItem(`${TOKEN_PREFIX}.token`, token);
            this.userEmail = email;
        },
        async reLogin() {
            this.wrongEmailDomain = true;
            window.localStorage.clear();
        },
    },
};
</script>


<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

.title {
    color: red;
    font-size: 18px;
    display: flex;
    align-items: center;
}
</style>
