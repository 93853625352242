import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/search",
    name: "SearchHcp",
    component: () => import("../views/SearchHcp.vue"),
  },
  {
    path: "/qrcode",
    name: "Qrcode",
    component: () => import("../views/Qrcode.vue"),
  },
  {
    path: "/form",
    name: "Form",
    component: () => import("../views/Form.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "*",
    redirect: "/search",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
