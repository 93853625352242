<template>
    <v-app-bar app color="white" height="90%">
        <v-container class="pa-0">
            <div class="top-row">
                <router-link :to="{ path: '/' }">
                    <img src="@/assets/roche.png" alt="" width="90px" />
                    <!-- roche-logo -->
                </router-link>
                <div class="title">{{ title }}</div>
            </div>
        </v-container>

        <template v-slot:extension>
            <v-container class="pa-0">
                <v-row no-gutters>
                    <v-col>
                        <v-tabs>
                            <v-tab link to="/search">搜尋醫師</v-tab>
                            <!-- <v-tab link to="/form">新增醫師</v-tab> -->
                            <v-tab link to="/profile">個人檔案</v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </v-app-bar>
</template>

<script>
export default {
    data() {
        return {
            title: process.env.VUE_APP_TITLE,
        };
    },
    methods: {
        showMenu() {
            this.$emit('open');
        },
    },
};
</script>

<style scoped>
.title {
    color: red;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-row {
    display: flex;
}
</style>
