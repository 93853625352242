import axios from "axios";
class API {
  constructor() {
    this.token = undefined;
    this.initInstance();
  }
  initInstance() {
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      timeout: 10000,
      headers: {
        "X-Custom-Header": "foobar",
        Authorization: `bearer ${this.token}`,
      },
    });
  }
  setToken(token) {
    this.token = token;
    this.initInstance();
  }

  get Auth() {
    const PREFIX = "auth";
    return {
      verifyOauthToken: (authCode) => {
        return this.instance.post(`/${PREFIX}/verify-oauth-token`, authCode);
      },
      verifyJwtToken: () => {
        return this.instance.get(`/${PREFIX}/verify-jwt-token`);
      },
    };
  }
  get Hcp() {
    const PREFIX = "hcp";
    return {
      search: (text) => {
        return this.instance.get(`/${PREFIX}/search?text=${text}`);
      },
      data: (id) => {
        return this.instance.get(`/${PREFIX}/data/${id}`);
      },
      create: (submit) => {
        return this.instance.post(`/${PREFIX}`, submit);
      },
    };
  }
  get Employee() {
    const PREFIX = "employee";
    return {
      findEmail: (email) => {
        return this.instance.get(`/${PREFIX}/find?email=${email}`);
      },
    };
  }
  get Data() {
    const PREFIX = "data";
    return {
      specialtyList: () => {
        return this.instance.get(`/${PREFIX}/specialty/list`);
      },
      hcoList: () => {
        return this.instance.get(`/${PREFIX}/hco/list`);
      },
      productDiseaseTaList: () => {
        return this.instance.get(`/${PREFIX}/product-disease/list`);
      },
      permissionList: () => {
        return this.instance.get(`/${PREFIX}/hcp-permission/list`);
      },
    };
  }
  get iFunction() {
    const PREFIX = "i-function";
    return {
      generateQRcode: (hcpId) => {
        return this.instance.post(`/${PREFIX}/generate-qrcode`, { hcpId });
      }
    }
  }
  getLineUrl() {
    return this.instance.get("/data/line/verify-url");
  }
}

export default new API();
